import { createRoot } from 'react-dom/client';

class RestApi {
    constructor() {
        this.baseUrl = '/v1/';
        this.container = document.getElementById('root');
        let child = document.createElement('div');
        child.id = 'backdrop';
        this.div = this.container.appendChild(child);
        document.body.appendChild(this.div);
        this.backdropRoot = createRoot(this.div); // Create the root once
        this.backdropRef = null; // Referencia al componente Backdrop
        this.renderBackdrop(false); // Render the Backdrop initially
    }

    renderBackdrop(open) {
        /*this.backdropRef = (
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100vw"
                height="100vh"
                bg="rgba(0, 0, 0, 0.5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="white"
                zIndex="overlay" // Similar a `theme.zIndex.drawer + 1` en MUI
            >
                <Spinner size="xl" color="inherit" />
            </Box>
        );
        this.backdropRoot.render(this.backdropRef); */// Use the created root to render
    }

    updateBackdrop(open) {
        this.renderBackdrop(open); // Update the Backdrop
    }

    loadHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                "Accept": "*/*",
                'Content-Type': 'application/json',
            }
            return this.headers;
        }
    }

    loadMultipartHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Authorization': `Bearer ${token}`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                'Content-Type': 'application/json'
            }
            return this.headers;
        }
    }

    loadToken = () => {
        return new Promise((resolve, reject) => {
            let intentos = 0;
            const buscarValor = () => {
                const authUser = JSON.parse(sessionStorage.getItem("authUser"));
                if (authUser.state.accessToken) {
                    resolve(authUser.state.accessToken);
                } else {
                    if (intentos < 1) {
                        intentos++;
                        setTimeout(buscarValor, 1000); // Espera 1 segundo antes de intentar nuevamente
                    } else {
                        reject('No se encontró ningún valor en el localStorage después de 5 intentos');
                    }
                }
            };
            buscarValor();
        });
    };

    handleResponse = async (response) => {
        if (!response.ok) {
            this.updateBackdrop(false); // Actualizamos el estado de open
            const error = await response.json();
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
        this.updateBackdrop(false); // Actualizamos el estado de open
        return response.json();
    };

    async get(url) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, { method: 'GET', headers: { ...headers } });
        return this.handleResponse(response);
    }

    async post(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
            redirect: 'follow'
        });
        return this.handleResponse(response);
    }

    async multipartPost(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadMultipartHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: data,
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async put(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async delete(url) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'DELETE',
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async fetchWithTimeout(endpoint, options = {}, timeout = 25000) {
        const baseUrl = `${process.env.REACT_APP_API_AI_GATEWAY}`;
        const url = `${baseUrl}${endpoint}`;
        
        const controller = new AbortController();
        const { signal } = controller;
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        try {
            const response = await fetch(url, { ...options, signal });
            clearTimeout(timeoutId);
            return response;
        } catch (error) {
            this.updateBackdrop(false);
            if (error.name === 'AbortError') {
                throw new Error('Fetch timeout');
            }
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    }
    
}

export default RestApi;