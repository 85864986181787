import GC from '@mescius/spread-sheets';
import '@mescius/spread-sheets-print';
import '@mescius/spread-sheets-io';
import '@mescius/spread-sheets-shapes';
import '@mescius/spread-sheets-charts';
import '@mescius/spread-sheets-slicers';
import '@mescius/spread-sheets-pivot-addon';
import '@mescius/spread-sheets-reportsheet-addon';
import "@mescius/spread-sheets-tablesheet";
import "@mescius/spread-sheets-ganttsheet";
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react';
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Box, Button, Flex, useBoolean } from '@chakra-ui/react';
import { useAxCalcStore } from 'src/store/axCalc.store';
import { BiDownload, BiSave } from 'react-icons/bi';
import AddFileToCalcModal from '../common/modals/AddFileToCalcModal';

GC.Spread.Common.CultureManager.culture('ja-jp');

// @ts-ignore
window.GC = GC;

GC.Spread.Sheets.LicenseKey = "orkestadoc.axeleratum.com|orkestadocqa.axeleratum.com|orkestadoc-mediaqa.axeleratum.com|orkestadoc-media.axeleratum.com,544153217483177#B1eNia5UTULBjV8g5bGB7QXh7dP5UbJNGSyBzawUHdVtESoBzavMlZnh6RyJGaXRnMzVzMkVFd4IVYOlnawZ7MNRHNZNEaBpnT0ZzZJJzKmZHNzsSVaJ5Z6d7ZlpnVEtWcYp4LBNzbGFVSqZUO8J5c7RVVMNWba3id7cHUjhEeTxmTZ9kUhNlZl5mRvZlQyYXS7kFRQF4Z53ES4Ika6hWbTRnbaJVSYpXcKhUevJzKtJncqJ4L5o6cQRXMn3mRh5UY4I7dtZ5cv2mdy4EN5AHbVlTa5RUO89WY5cGaLVVS6h6dlhlb4tmViV7TtBnbwkVTmhkQ6AlS7RWVwIzSrpGahZ5ZQN6bwpXOp3idxRke7UGVjd5amZTUBRWZaNVO82mTFxka7kmI0IyUiwiI5ETOxYUOFRjI0ICSiwSMzUTM6cTMyAjM0IicfJye#4Xfd5nIVF4SRJiOiMkIsIyNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLikjM5IDMxASMxATM4IDMyIiOiQncDJCLi46bj9Sb5RXYyVGblhXYuEWakVWbtM6bkFGdzV6ay3GLt36Yu4Wd4FmclxWZ8FmLhFXYpRWZt5yYvRWY4NXZrJ7bs46bj9Sb5RXYyVGblhXYuEWcj3GZhR7cltmcvxSbvNmLtVHdhJXZsVGeh9yYvRWY4NXZrJ7biojIz5GRiwiIsxWakFmUgIXatlGZhxmViojIh94QiwiI7cTMzgDN7EjMzUTM4QTNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdaRlQhRXbyo7SSNUcYJHNiJmWnd6ZI3CNXx6NptyKU54V5EkT684aFFmTR3keupLU";        // Enter a valid license key.      

const BiddingAxCalc = () => {

  const [showAddStage, setShowAddStage] = useBoolean(false);
  const { setSpread, save } = useAxCalcStore();

  let hostStyle = {
    width: '100%',
    height: '600px',
    border: '1px solid darkgray'
  }


  function initSpread(spread: GC.Spread.Sheets.Workbook) {
    setSpread(spread);
    // Init Status Bar
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(document.getElementById('statusBar')!);
    statusBar.bind(spread);
  }

  return (
    <Box mt={2}>
      <Flex justifyContent={'flex-end'} gap={3} mb={3}>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={() => save()} leftIcon={<BiDownload />}>
          Descargar
        </Button>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={setShowAddStage.on} leftIcon={<BiSave />} >
          Cargar Documento
        </Button>
      </Flex>
      <Box>
        <SpreadSheets workbookInitialized={spread => initSpread(spread)} hostStyle={hostStyle}>
          <Worksheet />
        </SpreadSheets>
      </Box>
      <div id="statusBar"></div>
      {showAddStage && (
        <AddFileToCalcModal onCancel={setShowAddStage.off} isOpen={true} />
      )}
    </Box>
  );
}

export default BiddingAxCalc;