import axios from "axios";
import { getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/dashboard`,
    timeout: 20000,
});

const utilAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/general/util`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const refreshDashboardPowerBI = async (idCompany: string) => {
    try {
        return await utilAxiosRequest.get(`/refreshDashboardPBI/companyId/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const downloadPoweBIToMail = async () => {
    try {
        return await utilAxiosRequest.get(`/powerBiDownloadRequest`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getUrlPowerBiDashboard = async (idCompany: string) => {
    try {
        return await axiosRequest.get(`/urlPowerBi/companyId/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const generateDataDashboard = async (idCompany: string) => {
    try {
        return await axiosRequest.post(`/loadDashboard/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getRoadMapDashboard = async (idCompany: string) => {
    try {
        return await axiosRequest.get(`/roadmap/companyId/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const patchRoadMapDashboard = async (idCompany: string, roadMapData: any) => {
    try {
        return await axiosRequest.patch(`/roadmap/companyId/${idCompany}`, roadMapData, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    getUrlPowerBiDashboard,
    generateDataDashboard,
    refreshDashboardPowerBI,
    downloadPoweBIToMail,
    getRoadMapDashboard,
    patchRoadMapDashboard
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
