import useAuthState from "../../../store/authState";
import companyIcon from "../../../assets/images/company-icon.png";
import useGlobalState from "../../../store/globalState";
import { b64toBlob } from "../../../lib/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { GoPerson } from 'react-icons/go';
import UserOutlineIcon from "../../icons/UserOutlineIcon";
import StaristicsList from "./StaristicsList";

const ProfileBox = () => {
    const { userName } = useAuthState((s) => s);
    const { getUserCompanyInfo, userInfo, userCompanyInfo } = useGlobalState()
    const [profileImage, setProfileImage] = useState(null);
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    useEffect(() => {
        if (userInfo?.data?.image64 && userInfo?.data?.image) {
            try {
                const blob = b64toBlob(userInfo.data.image64.split("base64,")[1], userInfo?.data?.image);
                const url = window.URL.createObjectURL(blob);
                setProfileImage(url);
            } catch (error) {
                console.log(error);
            }
        }
    }, [userInfo]);

    return (
        <div className="w-full flex justify-between p-2">
            <div className="flex items-center justify-center w-3/12 bg-gray-100 rounded-xl shadow-xl p-4 mr-4">
                <div className="flex flex-col items-start justify-center h-full">
                    <div className="flex items-center mb-4">
                      {/** <UserOutlineIcon boxSize={46} className="block w-16 mr-4" color={"red"} /> */} 
                        <div className="flex flex-col justify-center">
                            <h1 className="text-brand-600 text-3xl capitalize">{userCompanyInfo?.name}</h1>
                            <h2 className="text-brand-400 text-2xl capitalize">{userName ?? "N/A"}</h2>
                            <h2 className="text-brand-600 text-1xl">{"Número de trabajadores (30)"}</h2>

                        </div>
                    </div>
                </div>
            </div>
            <div className="w-9/12 bg-gray-100 rounded-xl shadow-xl p-4 ml-4">
                <StaristicsList />
            </div>
        </div>
    );
};


export default ProfileBox;

