import { useRef, useEffect } from 'react';
import './KlausChatBubble.css'; // Aquí puedes agregar el CSS
import KlausChatContainer from './KlausChatContainer';
import { useChatHistory } from 'src/store/KlausChatState';

const KlausChat = () => {
    const chatState = useChatHistory((s) => s);
    const chatRef = useRef(null); // Ref para el contenedor del chat

    // Hook para manejar clics fuera del chat
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatRef.current && !chatRef.current.contains(event.target)) {
                chatState.changeChatState(false);
            }
        };

        // Agrega un event listener cuando el componente está montado
        document.addEventListener('mousedown', handleClickOutside);

        // Elimina el event listener cuando el componente se desmonta
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [chatState]); // Se ejecuta solo cuando cambia el estado del chat

    return (
        <div className="chat-container">
            {chatState.chatOpen && (
                <div className="chat-window" ref={chatRef}>
                    <div className="chat-header">
                        <h4>SAGA</h4>
                        <button onClick={() => chatState.changeChatState(false)}>Cerrar</button>
                    </div>
                    <div className="chat-body">
                        <KlausChatContainer />
                    </div>
                </div>
            )}
            {chatState.chatOpenFromPDF && (
                <div className="chat-window">
                    <div className="chat-header">
                        <h4>SAGA</h4>
                        <button onClick={() => chatState.changeChatOpenFromPDF(false)}>Cerrar</button>
                    </div>
                    <div className="chat-body">
                        <KlausChatContainer />
                    </div>
                </div>
            )}
        </div>
    );
};

export default KlausChat;
