import CommonModal from "../../CommonModal";
import LinkButton from "../../buttons/LinkButton";
import { BiPlus } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import { FormControl, IconButton, Input, InputGroup, Tooltip, useBoolean, Alert, AlertIcon, Flex } from "@chakra-ui/react";
import { IoCheckmarkSharp, IoCloseOutline } from "react-icons/io5";
import useBiddingTypeState from "src/store/biddings/biddingTypesState";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../../../i18n/en";
import { Button, Divider } from "@chakra-ui/react";
import useAuthState from "../../../../store/authState";
import { CgListTree } from "react-icons/cg";
import StageTypeButton from "../../../biddingType/StageTypeButton";
import BiddingTypeGroupsPanel from "src/components/biddingType/BiddingTypeGroupPanel";
import AddNewStageGroup from "src/components/biddingType/AddNewStageGroup";
import useModalState from "src/store/modalsState";
import useDataGroupsState from "../../../../store/dataGroupsState";
import IconAdd from "src/assets/bidding-icons/IconAdd";
import { IoIosAddCircleOutline, IoMdNotificationsOutline } from "react-icons/io";
import BoxButton from "../../../../components/common/BoxButton";
import BackIcon from "../../../../assets/bidding-icons/BackIcon.png";
import ExpedientIcon from "src/assets/bidding-icons/ExpedientIcon";
import PowerIcon from "src/assets/bidding-icons/PowerIcon";
import AddPropertiesModal from "src/components/biddingType/AddPropertiesModal";
import { BsGraphUp } from "react-icons/bs";
import { FaRegFolderOpen } from "react-icons/fa";
import { DataGroup } from "./../../../../models/DataGroup.models";
import NewProcessIcon from "src/components/icons/iconsV2/NewProcessIcon";
import useBiddingValidationState from "src/store/biddings/biddingValidationTypesState";
import useBiddingDocumentTypeState from "src/store/biddings/biddingDocumentTypesState";
import useTemplateState from "src/store/templatesState";
import ModalReq from "./ModalReq";
import { replaceByIndex } from "src/lib/utils";
import useBiddingNotificationState from "src/store/biddings/biddingNotificationsState";
/* const AddNewTypeForm = ({
    onCancel,
    onSave,
    name,
    label,
}: {
    onCancel: () => any;
    onSave: (name: string, oldName: string) => any;
    name?: string;
    label: string;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [invalidName, setInvalidName] = useBoolean(false);
    const [typeName, setTypeName] = useState("");
    const [oldName] = useState(name);


    useEffect(() => {
        setTypeName(name);
    }, []);

    function handleSaveType() {
        if (!typeName?.length) {
            return setInvalidName.on();
        } else {
            setInvalidName.off();
        }
        onSave(typeName, oldName);
        setTypeName("");
    }

    return (
        <div className="w-full flex justify-between items-end gap-4">
            <FormControl flex={1} isInvalid={invalidName}>
                <label htmlFor="type_name" className="text-sm leading-8">
                    {label}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input
                        id="type_name"
                        type="text"
                        value={typeName}
                        rounded="none"
                        placeholder="Nombre"
                        onChange={(e) => setTypeName(e.target.value)}
                    />
                </InputGroup>
            </FormControl>
            <Tooltip label="Cancelar" fontSize="10px">
                <IconButton
                    variant="outline"
                    rounded="full"
                    colorScheme="red"
                    aria-label="cancel"
                    icon={<IoCloseOutline size={20} />}
                    onClick={onCancel}
                />
            </Tooltip>
            <Tooltip label="Guardar" fontSize="10px">
                <IconButton
                    variant="solid"
                    rounded="full"
                    colorScheme="brand"
                    aria-label="add"
                    icon={<IoCheckmarkSharp size={20} />}
                    onClick={handleSaveType}
                />
            </Tooltip>
        </div>
    );
}; */

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    dataPropsProps?: any;
};



const BiddingTypeModal = ({ isOpen, onClose, dataPropsProps }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const setStages = useBiddingNotificationState((state) => state.setStages);
    const setNewBiddingModal = useModalState((s) => s.setNewBiddingModal);
    const setNotificationsModal = useModalState((s) => s.setBiddingTypeNotificationsModal);

    const {
        createBiddingType,
        getBiddingTypesByCompany,
        updateBiddingType,
        dataGroupByTemplateIdArray,
        getAllDataGroupByTemplateIdArray,
        setArrayTemplatesId,
    } = useBiddingTypeState();

    const { getBiddingValidationTypes } = useBiddingValidationState();

    const { getBiddingDocumentTypes } = useBiddingDocumentTypeState();

    const { getTemplates } = useTemplateState();

    const [typeName, setTypeName] = useState("");
    const [typeSuperCategority, setTypeSuperCategority] = useState("");
    const [typeFormatNewProccessName, setTypeFormatNewProccessName] = useState("");
    const [arrayStages, setArrayStages] = useState([]);
    const [showAddStage, setShowAddStage] = useBoolean(false);
    const [showAddPropertiesModal, setShowAddPropertiesModal] = useBoolean(false);
    const [warning, setWarning] = useBoolean(false);
    const [showAddGroup, setShowAddGroup] = useBoolean(false);
    const [invalidName, setInvalidName] = useBoolean(false);
    const [expedient, setExpedient] = useState([]);
    const setBiddingTypeModalUrlPowerBi = useModalState((s) => s.setBiddingTypeModalUrlPowerBi);
    const [messageWarning, setMessageWarning] = useState("");
    const [selectedStage, setSelectedStage] = useState<{ name: string; groups: any[] }>(null);
    const setNewExpedientModal = useModalState((s) => s.setNewExpedientModal);
    const [powerBiUrl, setPowerBiUrl] = useState("");
    const [canSetExpedient, setCanSetExpedient] = useState(false);
    const [selectedFields, setSelectedFields] = useState({
        endDateField: false,
        powerBiURLField: false,
        hasExpedienteUnico: false,
        hasUrlGeneradores: false
    });
    const [createdBiddingTypeId, setCreatedBiddingTypeId] = useState("");
    const [isRenderComponent, setIsRenderComponent] = useState(false);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const rightContainerRef = useRef(null); // Ref para el contenedor derecho
    const [buttonWidth, setButtonWidth] = useState(0); // Estado para el ancho del botón

    useEffect(() => {
        if (isOpen) {
            const updateButtonWidth = () => {
                if (rightContainerRef.current) {
                    setButtonWidth(rightContainerRef.current.offsetWidth);
                }
            };

            setTimeout(() => {
                updateButtonWidth();
            }, 100);

            console.log(rightContainerRef?.current?.offsetWidth, buttonWidth, isOpen)

            // Escuchar cambios en el tamaño de la ventana para recalcular el ancho
            window.addEventListener('resize', updateButtonWidth);
            return () => window.removeEventListener('resize', updateButtonWidth);
        }
    }, [isOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const validateInputs = () => {
        if (!typeName?.length) {
            setMessageWarning(t("processNameRequired"));
            setWarning.on();
            return false;
        } else if (arrayStages.length === 0) {
            setMessageWarning("Debe agregar al menos una etapa, un grupo y un requerimiento");
            setWarning.on();
            return false;
        }
        return true;
    };

    async function onSaveBiddingType() {
        const idCompany = useAuthState.getState().companyId;
        const isCompleteInputs = validateInputs();
        if (isCompleteInputs === true) {
            let biddingType = {
                companyOwnerId: idCompany,
                createdAt: new Date(),
                defaultUrlPowerBi: powerBiUrl,
                documentSet: {
                    expedienteUnico: expedient,
                    name: typeName,
                    stages: arrayStages,
                },
                id: dataPropsProps.typeModal === "New" ? "" : dataPropsProps.biddingTypeId,
                name: typeName,
                updatedAt: new Date(),
                superCategority: typeSuperCategority,
                formatNewProccessName: typeFormatNewProccessName,
                hasEndDate: selectedFields.endDateField,
                hasUrlPowerBi: selectedFields.powerBiURLField,
                hasExpedienteUnico: selectedFields.hasExpedienteUnico,
                hasUrlGeneradores: selectedFields.hasUrlGeneradores
            };

            const res = dataPropsProps.typeModal === "New" ? await createType(biddingType) : await updateType(biddingType);

            // setCreatedBiddingTypeId(dataPropsProps.typeModal === "New" && res.id);
        }
    }

    const handleSelectField = (fieldName) => {
        setSelectedFields({
            ...selectedFields,
            [fieldName]: !selectedFields[fieldName],
        });
    };

    const createType = async (biddingType) => {
        const typeSaved = await createBiddingType(biddingType, false);
        if (typeSaved) {
            toast.success(t("newProcessTypeCreated"));
            getBiddingTypesByCompany();
            onClose();
        } else {
            toast.error(t("errorCreatingProcessType"));
        }
    };

    const updateType = async (biddingType) => {
        const typeSaved = await updateBiddingType(biddingType, false);
        if (typeSaved) {
            toast.success(t("updatedProcessType"));
            getBiddingTypesByCompany();
            onClose();
        } else {
            toast.error(t("errorUpdatingProcessType"));
        }
    };

    function addStage(name: string) {
        const newStage = { name, groups: [] };
        if (arrayStages.find((item) => item.name === name)) {
            toast.error("Ya existe una etapa con ese nombre");
        } else {
            const updatedJson = arrayStages.concat(newStage);
            setArrayStages(updatedJson);
            setShowAddStage.off();
            toast.success("Etapa creada");
        }
    }

    function editStage(data) {
        const tempStage = arrayStages.find((item) => item.name === data.oldName);
        tempStage.name = data.newName;
        const updatedJson = arrayStages.splice(tempStage);
        setArrayStages(updatedJson);
        toast.success("Etapa actualizada"); // agregar var nombre
    }

    function addGroup(data) {
        const newGroupAdd = { name: data.nameGroup, requirements: [] };
        const findStage = arrayStages.find((item) => item.name === data.stage);
        let groups = findStage.groups;
        const findGroup = groups.find((item) => item.name === data.nameGroup);
        if (findGroup) {
            toast.error("Ya existe un grupo en esta etapa con el mismo nombre");
        } else {
            groups.push(newGroupAdd);
            //const updatedJson = arrayStages.splice(findStage);
            const newStage = {
                ...findStage,
                groups: groups
            }
            const updatedJson = arrayStages.map(e => e.name === findStage.name ? newStage : e)
            setArrayStages(updatedJson);
            setSelectedStage(newStage);
            toast.success("Grupo creado"); // agregar var nombre
        }
    }

    function editGroup(nameGroup: string, oldNameGroup: string, stageName: string) {
        const findStage = arrayStages.find((item) => item.name === stageName);
        let groups = findStage.groups;
        const result = groups.map(e => (
            e.name === oldNameGroup ? {
                ...e,
                name: nameGroup
            } : e
        ))
        const newStage = {
            ...findStage,
            groups: result
        }
        //const updatedJson = arrayStages.splice(newStage);
        const updatedJson = arrayStages.map(e => e.name === findStage.name ? newStage : e)

        setArrayStages(updatedJson);
        setSelectedStage(newStage);
        toast.success("Grupo actualizado"); // agregar var nombre
    }

    function editMasiveGroup(nameGroup: string[], oldNameGroup: string[], stageName: string) {
        const findStage = arrayStages.find((item) => item.name === stageName);
        let groups = findStage.groups;
        const result = replaceByIndex(oldNameGroup, groups, nameGroup);
        const newStage = {
            ...findStage,
            groups: result
        }
        const updatedJson = arrayStages.map(e => e.name === findStage.name ? newStage : e)

        setArrayStages(updatedJson);
        setSelectedStage(newStage);
        toast.success("Grupo actualizado");
    }

    function editGroupSubGroup(nameGroup: string, oldNameGroup: string, stageName: string, subGroupName: string) {
        const findStage = arrayStages.find((item) => item.name === stageName);
        let groups = findStage.groups;
        const newName = subGroupName.replace(oldNameGroup, nameGroup)
        const findGroup = groups.find((item) => item.name === newName);
        if (findGroup) {
            toast.error("Ya existe un grupo en esta etapa con el mismo nombre");
        } else {
            let jsonTest = groups.map(grp => (
                grp.name.includes(subGroupName) ? {
                    ...grp,
                    name: grp.name.replace(subGroupName, newName)
                } : grp
            ))
            const newStage = {
                ...findStage,
                groups: jsonTest
            }
            const updatedJson = arrayStages.map(e => e.name === findStage.name ? newStage : e)
            setArrayStages(updatedJson);
            setSelectedStage(newStage);
            toast.success("Grupo actualizado"); // agregar var nombre
        }
    }

    function addRequirement(res: any) {
        let req = {
            biddingValidationTypeId: res.biddingValidationTypeId,
            documentTypeId: res.documentTypeId, // puede ser array de Ids
            fileLabel: res.fileLabel,
            name: res.name,
            templateId: res.templateId,
            signStatus: res.documentTypeId.includes("655ff58b1df4f015436bed44") ? "SI" : "", // VALIDA SI ES PDF DE FIRMA
            maxUploadable: res.maxUploadable,
            minUploadable: res.minUploadable,
            alwaysNew: res.alwaysNew,
            reviewStatus: res.reviewStatus === true ? "SI" : "", // Flujo de firma
            requerimentIdList: res.requerimentIdList
        };

        const findStage = arrayStages.find((item) => item.name === res.stage);
        let groups = findStage.groups;
        const findGroup = groups.find((item) => item.name === res.group);
        let group = findGroup.requirements;
        const groupFind = group.find((item) => item.name === res.name);
        if (groupFind) {
            toast.error("Ya existe un requerimiento en este grupo con el mismo nombre");
        } else {
            group.push(req);
            const updatedJson = arrayStages.splice(findStage);
            setArrayStages(updatedJson);
            setSelectedStage(findStage);
            toast.success("Requerimiento creado"); // agregar var nombre
        }
    }

    function editRequirement(res: any) {
        const findStage = arrayStages.find((item) => item.name === res.stage);
        let groups = findStage.groups;
        const findGroup = groups.find((item) => item.name === res.group);
        let requerimient = findGroup.requirements;
        const groupReq = requerimient.find((item) => item.name === res.oldName);
        groupReq.name = res.name;
        groupReq.fileLabel = res.fileLabel;
        groupReq.documentTypeId = res.documentTypeId;
        groupReq.biddingValidationTypeId = res.biddingValidationTypeId;
        groupReq.templateId = res.templateId;
        groupReq.signStatus = res.documentTypeId.includes("655ff58b1df4f015436bed44") ? "SI" : ""; // VALIDA SI ES PDF DE FIRMA
        groupReq.maxUploadable = res.maxUploadable;
        groupReq.minUploadable = res.minUploadable;
        groupReq.alwaysNew = res.alwaysNew;
        groupReq.reviewStatus = res.reviewStatus === true ? "SI" : "";
        groupReq.requerimentIdList = res.requerimentIdList;



        const updatedJson = arrayStages.splice(findStage);
        setArrayStages(updatedJson);
        setSelectedStage(findStage);
        toast.success("Requerimiento actualizado"); // agregar var nombre
    }

    const deleteStage = (name) => {
        const updatedJson = arrayStages.filter((item) => item.name !== name);
        setArrayStages(updatedJson);
        toast.success("Etapa eliminada"); // agregar var nombre
    };

    const deleteGroup = (data) => {
        let jsonTest = arrayStages?.map((elem) => {
            elem.groups = elem.groups.filter((req) => !req.name.includes(data));
            return elem;
        });
        setArrayStages(jsonTest);
        toast.success("Grupo eliminado"); // agregar var nombre
    };

    const deleteRequirement = (item) => {
        let nameStage = item.stage;
        let nameGroup = item.group;
        let nameRequiriment = item.nameReq;
        let json = arrayStages?.map((category) => {
            if (category.name === nameStage) {
                category.groups = category.groups?.map((group) => {
                    if (group.name === nameGroup) {
                        group.requirements = group.requirements.filter((requirement) => requirement.name !== nameRequiriment);
                    }
                    return group;
                });
            }
            return category;
        });
        setArrayStages(json);
        toast.success("Requerimiento eliminado"); // agregar var nombre
    };

    const handleSelectedStage = (item) => {
        setSelectedStage(item);
    };

    const handleDragStart = (index) => {
        setDraggedItemIndex(index);
        setIsDragging(true)
    }

    // Maneja el evento dragOver
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true)
    };

    // Maneja el evento drop
    const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
        e.preventDefault();

        if (draggedItemIndex !== null) {
            // Reordenar los elementos
            const newItems = [...arrayStages];
            const [removedItem] = newItems.splice(draggedItemIndex, 1);
            newItems.splice(dropIndex, 0, removedItem);
            setArrayStages(newItems);
            setDraggedItemIndex(null); // Restablece el índice del elemento arrastrado
        }
        setIsDragging(false)
    };

    function handleReplaceStage(selectedStage) {
        setSelectedStage(selectedStage);
        const newItems = arrayStages.map(e => e.name === selectedStage.name ? selectedStage : e)
        setArrayStages(newItems);
    }

    function handleAddGroup(typeName) {
        let newGroup = { nameGroup: typeName, stage: selectedStage.name };
        addGroup(newGroup);
        setShowAddGroup.off();
    }

    async function openExpedientModal() {
        const templateIds = [];
        arrayStages?.forEach((obj) => {
            obj?.groups?.forEach((group) => {
                group?.requirements?.forEach((requirement) => {
                    if (requirement?.templateId) {
                        templateIds?.push(requirement?.templateId);
                    }
                });
            });
        });
        // setArrayTemplatesId(templateIds);
        //await getAllDataGroupByTemplateIdArray(templateIds);
        //   if(dataGroupByTemplateIdArray.data){*/

        if (canSetExpedient) {
            setExpedient(dataGroupByTemplateIdArray?.data);
        }
        let dataGroups = dataGroupByTemplateIdArray?.data;
        setNewExpedientModal({ show: true, data: { groups: dataGroups, propsAction, arrayStages: templateIds, setCanSetExpedient } });
        //}
    }
    const propsAction = (item) => {
        setExpedient(item);
    };

    const addUrlPowerBi = (urlPowerBi) => {
        setPowerBiUrl(urlPowerBi);
        setShowAddPropertiesModal.off();
    };

    const saveProperties = (typePattern, superCategority) => {
        setTypeFormatNewProccessName(typePattern);
        setTypeSuperCategority(superCategority);
        setShowAddPropertiesModal.off();
    };

    function handleNotificationsModal() {
        setNotificationsModal({
            show: true,
            data: {
                bidding: "",
                stages: arrayStages,
                templateId: dataPropsProps.biddingTypeId,
            },
        });
    }

    useEffect(() => {
        if (dataPropsProps.typeModal === "Edit") {
            let nameBidd = dataPropsProps.biddingTypeStages;
            let stagesBidd = dataPropsProps.biddingTypeStages;
            setExpedient(dataPropsProps.expedienteUnico);
            setTypeFormatNewProccessName(dataPropsProps.biddingTypePattern);
            setTypeSuperCategority(dataPropsProps.biddingTypeCategory);
            setArrayStages(stagesBidd.stages);
            setTypeName(nameBidd.name);
            setPowerBiUrl(dataPropsProps.defaultUrlPowerBi);
            setSelectedFields({
                ...selectedFields,
                endDateField: dataPropsProps?.biddingTypeFields?.hasEndDate,
                powerBiURLField: dataPropsProps?.biddingTypeFields?.hasUrlPowerBi,
                hasExpedienteUnico: dataPropsProps?.biddingTypeFields?.hasExpedienteUnico,
                hasUrlGeneradores: dataPropsProps?.biddingTypeFields?.hasUrlGeneradores
            });
        }
    }, [
        dataPropsProps.biddingTypeCategory,
        dataPropsProps.biddingTypePattern,
        dataPropsProps.biddingTypeStages,
        dataPropsProps.defaultUrlPowerBi,
        dataPropsProps.expedienteUnico,
        dataPropsProps.typeModal,
        dataPropsProps.biddingTypeFields,
    ]);

    useEffect(() => {
        //clean selected stage when delete
        if (selectedStage) {
            if (!arrayStages.find((item) => item.name === selectedStage.name)) {
                setSelectedStage(null);
            }
        }
    }, [arrayStages]);

    useEffect(() => {
        // getDataGroups();

        const templateIds = [];
        arrayStages.forEach((obj) => {
            obj.groups.forEach((group) => {
                group.requirements.forEach((requirement) => {
                    if (requirement.templateId) {
                        templateIds.push(requirement.templateId);
                    }
                });
            });
        });
        setStages(arrayStages);
        getAllDataGroupByTemplateIdArray(templateIds);
    }, [arrayStages]);

    useEffect(() => {
        // request use on RequirimentModal
        getBiddingValidationTypes().catch((err) => {
            toast.error(t("error"));
        });

        getBiddingDocumentTypes().catch((err) => {
            toast.error(t("error"));
        });

        getTemplates().catch((err) => {
            toast.error(t("error"));
        });
    }, []);
    return (
        <>
            <CommonModal size="full" isOpen={isOpen} onClose={onClose} className="rounded-none">
                <div className="w-full flex flex-col  h-[95vh] overflow-auto">
                    <div className="w-full mb-3 flex justify-start">
                        <button onClick={onClose}>
                            <img src={BackIcon} alt="back icon" className="w-8 pb-2"></img>
                        </button>
                        <div className="pl-2">
                            {dataPropsProps.typeModal === "New" ? (
                                <h2 className="title-3">{t("newTypeProccess")} </h2>
                            ) : (
                                <h2 className="title-3">{t("editTypeProccess")} </h2>
                            )}
                        </div>
                    </div>
                    {warning && (
                        <Alert status="warning" className="cursor-pointer" onClick={setWarning.off}>
                            <AlertIcon />
                            {messageWarning}
                        </Alert>
                    )}
                    <main className="w-full flex h-full border-t border-dashed">
                        {/* left panel  */}
                        <section className="flex-1 flex flex-col pr-4 pl-2 border-r border-dashed overflow-y-auto relative justify-between">
                            <div className="py-2">
                                <div className="flex flex-col sticky top-0 bg-white z-10">
                                    <div className="title-3 mb-1 ">
                                        <FormControl flex={1} isInvalid={invalidName}>
                                            <label htmlFor="type_name" className="text-base leading-8 ">
                                                {t("nameProccess")}
                                            </label>
                                            <InputGroup borderColor="gray.400" className="rounded-lg border-2">
                                                <Input
                                                    id="type_name"
                                                    type="text"
                                                    rounded="none"
                                                    value={typeName}
                                                    placeholder="Nombre"
                                                    onChange={(e) => setTypeName(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormControl>
                                    </div>
                                    {/** <LinkButton leftIcon={<BiPlus size={18} />} onClick={setShowAddStage.on} className="mt-2">
                                    {t("addNewTypeBiddingStage")}
                                </LinkButton>*/}

                                    {/*showAddStage ? (
                                    <div className="mb-4">
                                        <AddNewTypeForm
                                            onCancel={setShowAddStage.off}
                                            name={""}
                                            label={"Agregar nueva etapa"}
                                            onSave={addStage}
                                        />
                                    </div>
                                ) : (
                                    <div className="mb-3"></div>
                                )*/}
                                </div>
                                <div className="align-center flex justify-between mt-2">
                                    <label htmlFor="type_name" className="text-base leading-8 text-[#0327e2]">
                                        {t("stages")}
                                    </label>
                                    <LinkButton leftIcon={<BiPlus size={18} />} onClick={setShowAddStage.on} >
                                        {t("addNewTypeBiddingStage")}
                                    </LinkButton>
                                </div>
                                <div className="flex flex-col items-center gap-3 mt-2"> {/* overflow-y-auto max-h-64 */}
                                    {arrayStages?.map((stage, idx) => (
                                        <div
                                            className={`w-full ${isDragging ? 'shadow-xl' : ''}`}
                                            key={idx}
                                            draggable
                                            onDragStart={() => handleDragStart(idx)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, idx)}
                                        >
                                            <StageTypeButton
                                                selected={selectedStage?.name === stage.name}
                                                text={stage.name}
                                                editStage={editStage}
                                                deleteStage={deleteStage}
                                                onClick={() => handleSelectedStage(stage)}
                                                icon={<CgListTree size={24} color="inherit" />}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="pt-4 pb-2">
                                    <div className="pb-2">
                                        <BoxButton
                                            icon={<IoIosAddCircleOutline size={24} color="#0327e2" />}
                                            buttonW={true}
                                            size={"xsmall"}
                                            text={t("addNewTypeBiddingStage")}
                                            tooltip={t("addNewStageToProcess")}
                                            onClick={setShowAddStage.on}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div /* className="overflow-y-auto max-h-64" */>
                                <div className="w-full flex gap-2 flex-col lg:flex-col justify-center border-t border-dashed pt-2">

                                    <BoxButton
                                        icon={<IoIosAddCircleOutline size={24} color="#0327e2" />}
                                        buttonW={true}
                                        size={"xsmall"}
                                        text={t("addProperties")}
                                        tooltip={t("addPropertiesToProcess")}
                                        onClick={setShowAddPropertiesModal.on}
                                    />
                                    <BoxButton
                                        icon={<FaRegFolderOpen size={24} color="#0327e2" />}
                                        buttonW={true}
                                        size={"xsmall"}
                                        text={t("editExpedientData")}
                                        tooltip={"Editar o eliminar grupo de datos que conforman el expediente único"}
                                        onClick={openExpedientModal}
                                    />
                                    { /* <BoxButton
                                    icon={<BsGraphUp size={24} color="#0327e2" />}
                                    //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                                    buttonW={true}
                                    size={"xsmall"}
                                    text={"Asociar Requerimientos"}
                                    tooltip={"Asociar Requerimientos"}
                                    onClick={openModal}
                                    //onClick={() => setBiddingTypeModalUrlPowerBi({ show: true, data: { addUrlPowerBi, powerBiUrl } })}
                                />*/}
                                    <BoxButton
                                        icon={<BsGraphUp size={24} color="#0327e2" />}
                                        buttonW={true}
                                        size={"xsmall"}
                                        text={t("addReportDashboard")}
                                        tooltip={"Agregar url de Power BI"}
                                        onClick={() => setBiddingTypeModalUrlPowerBi({ show: true, data: { addUrlPowerBi, powerBiUrl } })}
                                    />
                                    {dataPropsProps.typeModal === "Edit" && (
                                        <BoxButton
                                            icon={<IoMdNotificationsOutline size={24} color="#0327e2" />}
                                            buttonW={true}
                                            size={"xsmall"}
                                            text={t("notificationSetting")}
                                            tooltip={"Configurar Notificaciones"}
                                            onClick={handleNotificationsModal}
                                        />
                                    )}
                                </div>
                            </div>

                        </section>
                        {/* right panel  */}
                        <section ref={rightContainerRef} className="w-3/5 md:w-9/12 pr-2 pl-4 h-full overflow-y-auto pb-[40px]">
                            {Boolean(selectedStage) && (
                                <div className="w-full flex items-center justify-between py-4 border-b">
                                    <h3 className="font-light text-brand-500 italic" color={"brand.500"}>
                                        {selectedStage?.name}
                                    </h3>
                                    <LinkButton leftIcon={<BiPlus size={15} />} onClick={setShowAddGroup.on}>
                                        {t("addGroup")}
                                    </LinkButton>
                                </div>
                            )}
                            <div className="w-full pt-2 overflow-y-auto">
                                {selectedStage?.name ? (
                                    <div>
                                        <BiddingTypeGroupsPanel
                                            stage={selectedStage}
                                            setStage={handleReplaceStage}
                                            editGroup={editGroup}
                                            editMasiveGroup={editMasiveGroup}
                                            createSubGroup={handleAddGroup}
                                            editGroupSubGroup={editGroupSubGroup}
                                            deleteGroup={deleteGroup}
                                            addRequirement={addRequirement}
                                            editRequirement={editRequirement}
                                            deleteRequirement={deleteRequirement}
                                            onClick={() => handleSelectedStage(selectedStage)}
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <h3 className="">{t("noBiddingSelected")}</h3>
                                    </div>
                                )}
                            </div>
                        </section>
                    </main>
                    <div className={`absolute bottom-0 right-0 w-full bg-white py-2 border-t border-dashed bg-white z-10 flex-[2]`} style={{ width: `${buttonWidth + 24}px` }}>
                        <div className="w-full flex justify-center">
                            <Button
                                type="submit"
                                rounded="full"
                                size="md"
                                fontWeight="medium"
                                px={"2rem"}
                                onClick={onSaveBiddingType}
                                isLoading={false}
                            >
                                {t("save")}
                            </Button>
                        </div>
                    </div>
                </div>
            </CommonModal>

            {showAddGroup && (
                <AddNewStageGroup onCancel={setShowAddGroup.off} onSave={handleAddGroup} label={t("createGroup")} isOpen={true} />
            )}

            {showAddStage && (
                <AddNewStageGroup onCancel={setShowAddStage.off} onSave={addStage} label={t("addNewTypeBiddingStage")} isOpen={true} />
            )}


            <ModalReq isOpen={isModalOpen} onClose={closeModal} />

            {showAddPropertiesModal && (
                <AddPropertiesModal
                    onCancel={setShowAddPropertiesModal.off}
                    onSave={saveProperties}
                    label={t("processProperties")}
                    isOpen={true}
                    superCategority={typeSuperCategority}
                    formatNewProccessName={typeFormatNewProccessName}
                    handleSelectField={handleSelectField}
                    selectedFields={selectedFields}
                    data={{ addUrlPowerBi, powerBiUrl }}
                />
            )}
        </>
    );
};

export default BiddingTypeModal;
