import NotifcationList from "./NotifcationList";
import klaus_logo from "src/assets/images/klaus_logo.png";
import sigea from "src/assets/images/SIGEA.png";
import compliance from "src/assets/images/con_compliance.png";
import jmc from "src/assets/images/JM&C.png";
import BiddingAxRoadmap from "src/components/bidding/AxRoadmap/BiddingAxRoadmap";
import { Button, Spinner } from "@chakra-ui/react";
import { useAxRoadmapStore } from "src/store/axRoadmap.store";
import { useEffect, useState } from "react";
import dashboardServices from "src/services/dashboard.service";
import useCustomToast from "src/hooks/useCustomToast";

const BoxDashBoard = () => {
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    const toast = useCustomToast();
    const roadMapData = useAxRoadmapStore((s) => s.tasksRoadmapSelected);
    const setRoadMapData = useAxRoadmapStore((s) => s.setTasksRoadMapselect);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (roadMapData.length === 0) {
            getRoadMapData();
        }
    }, []);

    const handleSaveRoadMap = async () => {
        setLoading(true);
        const stringData = JSON.stringify(roadMapData);
        await dashboardServices.patchRoadMapDashboard(authUser.state.companyId, stringData).then((res) => {
            const data = JSON.parse(res.data.respuesta.roadMap);
            const processedData = data.map((item: any) => ({
                ...item,
                start: new Date(item.start),
                end: new Date(item.end),
            }));
            console.log(processedData);
            setRoadMapData(processedData);
            setTimeout(() => {
                toast.success('Información guardada.');
                setLoading(false);
            }, 1000);

        });
    };

    const getRoadMapData = async () => {
        setLoading(true);
        await dashboardServices.getRoadMapDashboard(authUser.state.companyId).then((res) => {
            const data = JSON.parse(res.data.respuesta.roadMap);
            const processedData = data.map((item: any) => ({
                ...item,
                start: new Date(item.start),
                end: new Date(item.end),
            }));
            console.log(processedData);
            setRoadMapData(processedData);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    };


    return (
        <div className="box-dashboard w-full flex flex-col items-start justify-between p-2 mb-2 overflow-hidden">
            <div className="flex w-full">
                <div className="h-full bg-gray-100 rounded-xl shadow-xl mr-4">
                    <NotifcationList />
                </div>
                <div className="flex-1" style={{ width: '70%' }}>
                    {/* Contenedor del BiddingAxRoadmap */}
                    <div className="bg-gray-100 rounded-xl shadow-xl h-full flex flex-col justify-between">
                        <BiddingAxRoadmap />
                        <div className="flex justify-center items-start p-5">
                            {roadMapData.length !== 0 ? (
                                <Button onClick={() => handleSaveRoadMap()} disabled={loading}>
                                    {loading ? <Spinner size="sm" /> : 'Guardar cambios'}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
    
            <footer className="w-full flex justify-center items-center p-4">
                <img className="w-[12%] mx-3" src={klaus_logo} />
                <img className="w-[12%] mx-3" src={sigea} />
                <p className="flex-1 text-brand-600 leading-8 text-center lg:block hidden" style={{ color: '#40C200', fontSize: '30px' }}>
                    {"Plataforma Integral de Cumplimiento ASG"}
                </p>
                <img className="w-[14%] mx-3" src={compliance} />
                <img className="w-[6%] mx-3" src={jmc} />
            </footer>
        </div>
    );
    
};

export default BoxDashBoard;
