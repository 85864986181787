import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsX } from "react-icons/bs";
import { MdNextPlan } from "react-icons/md";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useCustomToast from "src/hooks/useCustomToast";
import { FaFileDownload } from "react-icons/fa";
import saga_logo from 'src/assets/images/saga_logo.png'
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import useModalState from "src/store/modalsState";
import { useChatHistory } from "src/store/KlausChatState";

const BiddingPdfViewerTopbar = ({
    showCloseDrawer,
    onCloseDrawer,
    onPrev,
    onNext,
    currentPage,
    totalPages,
    setCurrentPage,
    documentName,
    onDownload,
    onZoomIn,
    onZoomOut,
}) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [pageInputValue, setPageInputValue] = useState(1);
    const state = useModalState((s) => s);
    const chatState = useChatHistory((s) => s);

    const handleGoToPageByInput = () => {
        if (pageInputValue < 1 || pageInputValue > totalPages) {
            toast.error(t("invalidPage"), t("warning"));
        } else {
            setCurrentPage(pageInputValue);
        }
    };

    return (
        <header className="w-full bg-brand-600 px-1 py-1 flex items-center justify-between">
            <div className="flex gap-2 items-center">
            {
            showCloseDrawer &&
            
                <button onClick={onCloseDrawer}>
                    <BsX color="white" size={34} />
                </button> 
            }
                <h2 className="text-white text-xl pl-5">{documentName}</h2>
            </div>

            <div className="flex items-center gap-4 pr-2">
                <div className="flex items-center gap-2 text-white">
                    <button onClick={onZoomOut}>
                        <AiFillMinusCircle size={22} />
                    </button>
                    <div>
                        {t("size")}
                    </div>
                    <button onClick={onZoomIn}>
                        <AiFillPlusCircle size={22} />
                    </button>
                </div>

                <div className="flex items-center text-white gap-1">
                    <button className="disabled:text-gray-500/40 disabled:cursor-not-allowed" onClick={onPrev} disabled={false}>
                        <TiChevronLeft size={20} />
                    </button>
                    <span>
                        {t("page")} {currentPage}/{totalPages}
                    </span>
                    <button className="disabled:text-gray-500/40 disabled:cursor-not-allowed" onClick={onNext} disabled={false}>
                        <TiChevronRight size={20} />
                    </button>
                </div>

                <div className="flex items-center gap-1">
                    <NumberInput
                        size={"xs"}
                        bg="white"
                        w="80px"
                        variant={"filled"}
                        value={pageInputValue}
                        onChange={(_, n) => setPageInputValue(n)}
                        max={totalPages}
                        min={1}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <button onClick={handleGoToPageByInput}>
                        <MdNextPlan color="white" size={26} />
                    </button>
                </div>

                <div className="h-12 w-[1px] bg-gray-300"></div>

                <Tooltip label={t("download")}>
                    <button onClick={onDownload}>
                        <FaFileDownload color="white" size={28} />
                    </button>
                </Tooltip>
                {state?.sagaChatKlaus && (
                        <div 
                            style={{cursor: 'pointer', backgroundImage: 'linear-gradient(45deg, #2e8b59 0%, #56bc5b 50%, #232896 100%)', borderRadius: '50%'}} 
                            onClick={() => {chatState.changeChatOpenFromPDF(true)}}>
                            <img src={saga_logo} />
                        </div>
                    )}
            </div>
        </header>
    );
};

export default BiddingPdfViewerTopbar;
