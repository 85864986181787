import React from 'react';
import {
    makeStyles,
    shorthands,
    tokens,
    Text,
    mergeClasses,
    Link,
    Skeleton,
    SkeletonItem,
} from "@fluentui/react-components";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...shorthands.gap('8px'),
    },
    messageList: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),
        width: '100%',
    },
    messageItem: {
        display: 'flex',
        width: '100%',
    },
    messageSent: {
        justifyContent: 'flex-end', // Mensajes enviados alineados a la derecha
    },
    messageReceived: {
        justifyContent: 'flex-start', // Mensajes recibidos alineados a la izquierda
    },
    messageTextContainer: {
        maxWidth: '70%', // Limita el ancho de los mensajes
        padding: '0.75rem', // Espacio alrededor del texto
        borderRadius: '12px', // Redondea las esquinas
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Sombra ligera para efecto de caja
    },
    messageSentTextContainer: {
        backgroundColor: '#0344DC', // Fondo azul para mensajes enviados
        borderBottomRightRadius: '0px', // Esquina inferior derecha recta
    },
    messageReceivedTextContainer: {
        backgroundColor: '#F1F7FF', // Fondo gris claro para mensajes recibidos
        borderBottomLeftRadius: '0px', // Esquina inferior izquierda recta
    },
    messageText: {
        whiteSpace: 'pre-wrap',
    },
    messageSentText: {
        color: '#FFFFFF', // Color de texto para mensajes enviados
    },
    messageReceivedText: {
        color: tokens.colorNeutralForegroundInverted, // Color de texto para mensajes recibidos
    },
    metadataContainer: {
        display: 'flex',
        gap: '8px',
        flexDirection: 'row',
    },
});

export const HistoryChats = ({ messages = [], selectedDocument = null }) => {
    const styles = useStyles();
    const textEmpyFlag = "-1";

    return (
        <div className={styles.container}>
            <div className={styles.messageList}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={mergeClasses(
                            styles.messageItem,
                            message.isSender ? styles.messageSent : styles.messageReceived
                        )}
                    >
                        <div
                            className={mergeClasses(
                                styles.messageTextContainer,
                                message.isSender ? styles.messageSentTextContainer : styles.messageReceivedTextContainer
                            )}
                        >
                            {/* Si no es el remitente y tiene imagen, la muestra */}
                            {!message.isSender && message.image && (
                                <img height={"90%"} width={"70%"} src={`data:image/png;base64,${message.image}`} alt="message" />
                            )}

                            {/* Texto del mensaje */}
                            <Text
                                className={mergeClasses(
                                    styles.messageText,
                                    message.isSender ? styles.messageSentText : styles.messageReceivedText
                                )}
                            >
                                {message.secondary === textEmpyFlag ? (
                                    <Skeleton>
                                        <SkeletonItem />
                                    </Skeleton>
                                ) : (
                                    message.secondary
                                )}
                            </Text>

                            {/* Subdata y metadata solo para mensajes recibidos */}
                            {!message.isSender && (
                                <div>
                                    {/* Subdata (información adicional) */}
                                    {message.subdata && (
                                        <Text className={styles.messageText} italic size={200}>
                                            {`Procesando: \n ${message.subdata}`}
                                        </Text>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
